'use client';

import { Swiper, SwiperSlide } from 'swiper/react';
import { useState } from 'react';
import {
    Pagination,
    Controller,
    EffectFade,
    Navigation,
    Zoom,
} from 'swiper/modules';
import { GalleryTop } from '../elements/gallery-top';
import classNames from 'classnames';
import { Media } from '@/components/media';
import { RichText } from '@/components/rich-text';

import { useExpand } from '@/lib/hooks/use-expand';
import { getSizes } from '@/lib/utils/get-sizes';

export const Gallery1dContent = ({ items }) => {
    const [swiperOne, setSwiperOne] = useState(null);
    const [count, setCount] = useState(1);
    const nextSlide = () => {
        swiperOne.slideNext();
    };
    const prevSlide = () => {
        swiperOne.slidePrev();
    };
    const { expand } = useExpand();
    return (
        <div className="gallery gallery--1d">
            <GalleryTop
                count={count}
                items={items}
                prevSlide={prevSlide}
                nextSlide={nextSlide}
            />
            <Swiper
                className={classNames('gallery__main', {
                    'gallery__main--expand': expand,
                })}
                modules={[Pagination, EffectFade, Navigation, Zoom, Controller]}
                pagination={{
                    type: 'progressbar',
                }}
                zoom={true}
                autoHeight={true}
                loop={expand ? false : true}
                onSwiper={setSwiperOne}
                touchStartPreventDefault={false}
                onSlideChange={(slide) => {
                    setCount(slide.realIndex + 1);
                }}
                effect="fade"
            >
                {items.map((item, index) => {
                    const { media, title, description, theme } = item;
                    return (
                        <SwiperSlide key={index} className="gallery__slide">
                            <div
                                className={classNames('pt-6 pb-6', {
                                    [`bg-${theme}`]: theme,
                                })}
                            >
                                <RichText
                                    className="gallery__title"
                                    data={title}
                                />
                                <RichText data={description} />
                            </div>

                            <Media
                                wrapperClassName={'gallery__media'}
                                fill
                                objectFit={'contain'}
                                objectPosition={'center'}
                                media={media}
                                defaultBackground={'white'}
                                disableModal
                                disableCaption
                                disableElevation
                                slideChange={count}
                                sizes={getSizes({
                                    desktop: 58,
                                    tablet: 97,
                                })}
                            />
                        </SwiperSlide>
                    );
                })}
            </Swiper>
        </div>
    );
};
