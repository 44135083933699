'use client';

import { Swiper, SwiperSlide } from 'swiper/react';
import { useState } from 'react';
import {
    Pagination,
    Controller,
    EffectFade,
    Navigation,
    Zoom,
} from 'swiper/modules';
import { GalleryTop } from './gallery-top';
import classNames from 'classnames';
import { Media } from '@/components/media';
import { RichText } from '@/components/rich-text';

import { useExpand } from '@/lib/hooks/use-expand';
import { getSizes } from '@/lib/utils/get-sizes';

export const Gallery1cContent = ({ items }) => {
    const [controlledSwiper, setControlledSwiper] = useState(null);
    const [swiperOne, setSwiperOne] = useState(null);
    const [count, setCount] = useState(1);
    const nextSlide = () => {
        swiperOne.slideNext();
    };
    const prevSlide = () => {
        swiperOne.slidePrev();
    };
    const { expand } = useExpand();
    return (
        <div className="gallery gallery--1c">
            <GalleryTop
                count={count}
                items={items}
                prevSlide={prevSlide}
                nextSlide={nextSlide}
            />
            <Swiper
                className={classNames('gallery__main', {
                    'gallery__main--expand': expand,
                })}
                modules={[Pagination, EffectFade, Navigation, Zoom, Controller]}
                pagination={{
                    type: 'progressbar',
                }}
                zoom={true}
                loop={expand ? false : true}
                controller={{ control: controlledSwiper }}
                onSwiper={setSwiperOne}
                touchStartPreventDefault={false}
                onSlideChange={(slide) => {
                    setCount(slide.realIndex + 1);
                }}
                effect="fade"
            >
                {items.map((item, index) => {
                    const { media, title, description } = item;
                    return (
                        <SwiperSlide key={index} className="gallery__slide">
                            <Media
                                wrapperClassName={'gallery__media'}
                                fill
                                objectFit={'contain'}
                                objectPosition={'center'}
                                media={media}
                                defaultBackground={'white'}
                                disableModal
                                disableCaption
                                disableElevation
                                slideChange={count}
                                sizes={getSizes({
                                    desktop: 58,
                                    tablet: 97,
                                })}
                            />
                            {expand && (
                                <div>
                                    <RichText
                                        className="mt-4 gallery__title"
                                        data={title}
                                    />
                                    <RichText
                                        className="mb-4"
                                        data={description}
                                    />
                                </div>
                            )}
                        </SwiperSlide>
                    );
                })}
            </Swiper>

            <Swiper
                onSwiper={setControlledSwiper}
                slidesPerView={1}
                modules={[EffectFade]}
                effect="fade"
                autoHeight={true}
                loop={true}
            >
                {!expand &&
                    items.map((item, index) => {
                        const { title, description, theme } = item;
                        return (
                            <SwiperSlide key={index}>
                                <div
                                    className={classNames('mt-6', {
                                        [`bg-${theme}`]: theme,
                                    })}
                                >
                                    {title?.value && (
                                        <RichText
                                            className="gallery__title"
                                            data={title}
                                        />
                                    )}
                                    {description?.value && (
                                        <RichText data={description} />
                                    )}
                                </div>
                            </SwiperSlide>
                        );
                    })}
            </Swiper>
        </div>
    );
};
