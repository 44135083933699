'use client';

import { Swiper, SwiperSlide } from 'swiper/react';
import { useState, useRef, useEffect } from 'react';
import {
    Pagination,
    EffectFade,
    Navigation,
    Controller,
    Thumbs,
} from 'swiper/modules';
import classNames from 'classnames';
import { Media } from '@/components/media';
import { RichText } from '@/components/rich-text';
import { GalleryTop } from './gallery-top';
import { GalleryThumbs } from './gallery-thumbs';

import { useExpand } from '@/lib/hooks/use-expand';
import { getSizes } from '@/lib/utils/get-sizes';

export const Gallery1aContent = ({ items, settings }) => {
    const [controlledSwiper, setControlledSwiper] = useState(null);
    const [descriptionHeight, setDescriptionHeight] = useState(null);
    const [swiperOne, setSwiperOne] = useState(null);
    const [swiperThumbs, setSwiperThumbs] = useState(null);
    const [count, setCount] = useState(1);
    const imageRef = useRef();
    useEffect(() => {
        const getHeight = () => {
            setDescriptionHeight((prev) => imageRef.current.offsetHeight);
        };
        if (!descriptionHeight) {
            setDescriptionHeight(imageRef.current.offsetHeight);
        }
        window.addEventListener('resize', getHeight);
        return () => {
            window.removeEventListener('resize', getHeight);
        };
    }, []);
    const nextSlide = () => {
        if (count >= items.length) {
            swiperOne.slideTo(0);
            swiperThumbs.slideTo(0);
        }
        if (count < items.length) {
            swiperOne.slideNext();
            swiperThumbs.slideNext();
        }
    };
    const prevSlide = () => {
        swiperOne.slidePrev();
        swiperThumbs.slidePrev();
    };

    const { expand } = useExpand();
    return (
        <div className="gallery gallery--1a">
            <GalleryTop
                count={count}
                items={items}
                prevSlide={prevSlide}
                nextSlide={nextSlide}
            />
            <Swiper
                className={classNames('gallery__main', {
                    'gallery__main--expand': expand,
                })}
                modules={[
                    Pagination,
                    EffectFade,
                    Navigation,
                    Controller,
                    Thumbs,
                ]}
                pagination={{
                    type: 'progressbar',
                }}
                thumbs={{
                    swiper:
                        swiperThumbs && !swiperThumbs.destroyed
                            ? swiperThumbs
                            : null,
                }}
                controller={{ control: controlledSwiper }}
                onSwiper={setSwiperOne}
                onSlideChange={(swiper) => {
                    setCount(swiper.realIndex + 1);
                }}
                effect="fade"
                allowTouchMove={false}
            >
                {items.map((item, index) => {
                    const { title, description, media, enable_modal } = item;
                    return (
                        <SwiperSlide key={index} className="gallery__slide">
                            <div
                                className={'gallery__sidebar'}
                                style={{
                                    maxHeight: descriptionHeight,
                                }}
                            >
                                {title?.value && (
                                    <RichText
                                        className="gallery__title"
                                        data={title}
                                    />
                                )}
                                {description?.value && (
                                    <RichText data={description} />
                                )}
                            </div>
                            <div className="gallery__media-container">
                                <div ref={imageRef}>
                                    <Media
                                        wrapperClassName={'gallery__media'}
                                        fill
                                        objectFit={'contain'}
                                        objectPosition={'center'}
                                        media={media}
                                        defaultBackground={'white'}
                                        disableModal={!enable_modal}
                                        disableCaption
                                        disableElevation
                                        slideChange={count}
                                        sizes={getSizes({
                                            desktop: 38,
                                            tablet: 97,
                                        })}
                                    />
                                </div>
                            </div>
                        </SwiperSlide>
                    );
                })}
            </Swiper>
            <GalleryThumbs
                setSwiperThumbs={setSwiperThumbs}
                settings={settings}
                items={items}
            />
            <Swiper
                className="gallery__mobile-description"
                onSwiper={setControlledSwiper}
                slidesPerView={1}
                modules={[EffectFade]}
                effect="fade"
                autoHeight={true}
            >
                {items.map((item, key) => {
                    const { title, description, theme } = item;
                    return (
                        <SwiperSlide key={key}>
                            <div
                                className={classNames({
                                    [`bg-${theme}`]: theme,
                                })}
                            >
                                {title.value && (
                                    <RichText
                                        className="gallery__title"
                                        data={title}
                                    />
                                )}
                                {description?.value && (
                                    <RichText data={description} />
                                )}
                            </div>
                        </SwiperSlide>
                    );
                })}
            </Swiper>
        </div>
    );
};
