'use client';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, EffectFade } from 'swiper/modules';
import { Media } from '@/components/media';
import { GalleryTop } from '../elements/gallery-top';
import { useState } from 'react';
import classNames from 'classnames';
import { useExpand } from '@/lib/hooks/use-expand';
import { getSizes } from '@/lib/utils/get-sizes';

export const Gallery2aContent = ({ items }) => {
    const [swiperOne, setSwiperOne] = useState(null);
    const [count, setCount] = useState(1);
    const nextSlide = () => {
        if (count >= items.length) {
            swiperOne.slideTo(0);
        }
        if (count < items.length) {
            swiperOne.slideNext();
        }
    };
    const prevSlide = () => {
        swiperOne.slidePrev();
    };
    const { expand } = useExpand();
    return (
        <div className="gallery gallery--2a">
            <GalleryTop
                count={count}
                items={items}
                prevSlide={prevSlide}
                nextSlide={nextSlide}
                reduceCount
            />
            <Swiper
                className={classNames('gallery__main', {
                    'gallery__main--expand': expand,
                })}
                slidesPerView={1}
                spaceBetween={0}
                breakpoints={{
                    420: {
                        slidesPerView: 2,
                        spaceBetween: 10,
                    },
                }}
                modules={[Pagination, EffectFade]}
                pagination={{
                    type: 'progressbar',
                }}
                onSwiper={setSwiperOne}
                onSlideChange={(swiper) => {
                    setCount(swiper.realIndex + 1);
                }}
            >
                {items.map((item, index) => {
                    const { media } = item;
                    return (
                        <SwiperSlide key={index} className="gallery__slide">
                            <Media
                                wrapperClassName={'gallery__media'}
                                fill
                                objectFit={'contain'}
                                objectPosition={'center'}
                                media={media}
                                defaultBackground={'white'}
                                disableCaption
                                disableElevation
                                sizes={getSizes({
                                    desktop: 29,
                                    tablet: 48,
                                })}
                            />
                        </SwiperSlide>
                    );
                })}
            </Swiper>
        </div>
    );
};
