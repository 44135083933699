'use client';

import { useEffect, useState } from 'react';
import { Media } from '@/components/media';
import { Pagination } from '@/components/pagination';
import { getSizes } from '@/lib/utils/get-sizes';

export const Gallery3aContent = ({ items }) => {
    const [paginatedItems, setPaginatedItems] = useState([]);
    const [pages, setPages] = useState(1);
    const [offset, setOffset] = useState(0);
    const perPage = 12;

    useEffect(() => {
        setPages(Math.ceil(items.length / perPage));
    }, []);

    useEffect(() => {
        setPaginatedItems(items.slice(offset, offset + perPage));
    }, [offset]);

    const handleChangePage = (page) => {
        setOffset(page === 1 ? 0 : (page - 1) * perPage);
    };
    return (
        <>
            <div className="grid-md-3 grid-2 gap-2 row-gap-md-6 gallery--3a">
                {paginatedItems.map((item, index) => {
                    const { media } = item;
                    return (
                        <Media
                            wrapperClassName={'gallery__media'}
                            fill
                            objectFit="cover"
                            objectPosition="top"
                            key={index}
                            media={media}
                            defaultBackground={'white'}
                            disableCaption
                            disableElevation
                            sizes={getSizes({
                                desktop: 19,
                                tablet: 32,
                            })}
                        />
                    );
                })}
            </div>
            {pages > 1 && (
                <Pagination
                    className={'mt-14'}
                    pages={pages}
                    onChangePage={handleChangePage}
                />
            )}
        </>
    );
};
